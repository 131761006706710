const Arrow = ({width = "28", height = "25'"}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.6162 12.5537L15.7336 24.2777L13.7663 22.2906L22.3831 13.9844L0.545898 13.9844L0.545901 11.1229L22.3831 11.1229L14.0811 2.53857L16.0484 0.591186L27.6162 12.5537Z"
        fill="white"
      />
    </svg>
  );
};

export default Arrow;

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import AgentMiddleBar from "../components/Agent/AgentMiddleBar";
import AgentContent from "../components/Agent/AgentContent";
import AgentHeader from "../components/Header/Header";
import { useGetToken } from "../hooks/useGetToken";

const AgentPage = () => {
  const location = useLocation();
  const [configData, setConfigData] = useState(null);
  const [loadingTokenData, token] = useGetToken();

  useEffect(() => {
    const fetchConfig = async () => {
      const configFileName = location.state?.configFile || "blank_chat.json"; // Fallback to a default config if not provided
      const config = await import(`../data/agents/${configFileName}`);
      setConfigData(config);
    };

    fetchConfig();
  }, [location.state]);

  useEffect(() => {
    if (true) {
      setTimeout(() => {
        window.scrollTo({
          top: 90,
          behavior: "smooth",
        });
      }, 500); // Adjust the delay as needed
    }
  }, [configData]);

  if (!configData) {
    return <div>Loading...</div>; // Show a loading state until the config is loaded
  }

  if (loadingTokenData) {
    return (
      <>
        <AgentHeader />
        <div>Loading...</div>
      </>
    );
  }

  return (
    <>
      <AgentHeader />
      <AgentMiddleBar myData={configData} />
      <AgentContent myData={configData} token={token} />
    </>
  );
};

export default AgentPage;

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import AgentMiddleBar from "../components/AgentWebsockets/AgentMiddleBar";
import AgentContent from "../components/AgentWebsockets/AgentContent";
import AgentHeader from "../components/Header/Header";
import { useGetToken } from "../hooks/useGetToken";

const AgentPageWebsocket = () => {
  const [loadingTokenData, token] = useGetToken();
  // const location = useLocation();
  const [configData, setConfigData] = useState(null);
  console.log("loading token", loadingTokenData);
  console.log("token", token);

  // useEffect(() => {
  //     const fetchConfig = async () => {
  //       const configFileName = location.state?.configFile || 'blank_chat.json'; // Fallback to a default config if not provided
  //       const config = await import(`../data/agents/${configFileName}`);
  //       setConfigData(config);
  //     };

  //     fetchConfig();

  //   }, [location.state]);

  //   useEffect(() => {
  //     if (true) {
  //       setTimeout(() => {
  //         window.scrollTo({
  //           top: 90,
  //           behavior: 'smooth'
  //         });
  //       }, 500); // Adjust the delay as needed
  //     }
  //   }, [configData]);

  //   if (!configData) {
  //     return <div>Loading...</div>; // Show a loading state until the config is loaded
  //   }

  if (loadingTokenData) {
    return (
      <>
        <AgentHeader />
        <div>Loading...</div>
      </>
    );
  }
  return (
    <>
      This is a test page check console for more info
      {/* <AgentHeader />
        <AgentMiddleBar myData={configData}/>
        <AgentContent myData={configData}/> */}
    </>
  );
};

export default AgentPageWebsocket;

import React from "react";
import styles from "./AgentTile.module.css";
import { Arrow } from "../IconFiles";
import parse from "html-react-parser";

const AgentTile = ({ name, description, onClick }) => {
  return (
    <div className={styles.agentTile} onClick={onClick}>
      <div className={styles.agentValue}>
        <h2>{name}</h2>
        <p>{parse(description)}</p>
      </div>
      <div className={styles.arrow}>
        <Arrow />
      </div>
    </div>
  );
};

export default AgentTile;

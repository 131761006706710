// replace the user pool region, id, and app client id details
export default {
  Auth: {
    Cognito: {
      userPoolClientId: process.env.REACT_APP_AWS_USERPOOL_APP_CLIENT_ID,
      userPoolId: process.env.REACT_APP_AWS_USERPOOL_ID,
      loginWith: {
        oauth: {
          provider: "OKTA",
          domain: process.env.REACT_APP_COGNITO_DOMAIN,
          scopes: ["email", "openid"],
          redirectSignIn: [
            process.env.REACT_APP_DOMAIN,
            process.env.REACT_APP_DOMAIN,
          ],
          redirectSignOut: [`${process.env.REACT_APP_DOMAIN}logout`],
          responseType: "code",
        },
        username: "true",
      },
    },
  },
};

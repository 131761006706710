import React from 'react';
import styles from './CanvasDescription.module.css';

const CanvasDescription = ({ name, description }) => {
  return (
    <div className={styles.canvasDescription}>
      <h1>{name} Canvas</h1>
      <p>{description}</p>
    </div>
  );
};

export default CanvasDescription;
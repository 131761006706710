import React from "react";
import style from "./AgentMiddleBar.module.css";
import NavBar from "../NavBar/NavBar";
import { Arrow } from "../IconFiles";

const AgentMiddleBar = ({ myData }) => {
  return (
    <>
      <div className={style.app}>
        <NavBar />
        <div className={style.tabHolder}></div>
        <div className={style["large-label-holder"]}>
          <div className={style["large-label-text"]}>
            {myData["agent_name"]}
          </div>

          {myData["agent_name"] === "Boolean Generator" ? (
            <a
              href="https://q.sparksandhoney.com/home"
              target="_blank"
              rel="noopener Noreferrer"
              className={style["large-label-text"]}
            >
              <span>Search in Q</span> <Arrow width="17" height="20" />
            </a>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default AgentMiddleBar;

import React from "react";
import styles from "./CanvasItem.module.css";
import Arrow from "../IconFiles/Arrow";

function CanvasItem({ name, description, onClick }) {
  return (
    <li className={styles.canvasItem} onClick={onClick}>
      <div className={styles.canvasContent}>
        <div className={styles.itemName}>{name}</div>
        <div className={styles.itemDesc}>{description}</div>
        <div className={styles.canvasArrow}>
          <Arrow />
        </div>
      </div>
    </li>
  );
}

export default CanvasItem;

import React from 'react';
import styles from './CanvasNavigation.module.css';

const CanvasNavigation = ({ canvases, onCanvasSelect, selectedCanvas }) => {
  return (
    <div className={styles.canvasNavigation}>
      <ul>
        {canvases.map((canvas, index) => (
          <li
            key={index}
            className={selectedCanvas === canvas.name ? styles.active : ''}
            onClick={() => onCanvasSelect(canvas.name)}
          >
            {canvas.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CanvasNavigation;

// chatAPI.js

let conversation_id = "";

export const sendChatMessage = async (
  inputValues,
  userPrompt,
  apiKey,
  responseMode = "blocking",
  user,
  conversation_id,
  authToken
) => {
  const requestData = {
    input: inputValues,
    message: userPrompt,
    stream: responseMode === "streaming",
    conversation_id: conversation_id,
    user_id: user,
    prompt_name: apiKey,
  };

  try {
    if (responseMode === "blocking") {
      const response = await fetch(
        "https://canvasai-fastapi.criticalmass.com/chat/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + authToken,
          },
          body: JSON.stringify(requestData),
        }
      );

      const data = await response.json();

      if (data.conversation_id) {
        conversation_id = data.conversation_id;
      }

      return {
        type: "blocking",
        data: data,
        conversation_id: conversation_id,
      };
    } else if (responseMode === "streaming") {
      const response = await fetch(
        "https://canvasai-fastapi.criticalmass.com/chat/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + authToken,
          },
          body: JSON.stringify(requestData),
        }
      );

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      return {
        type: "streaming",
        stream: {
          async *[Symbol.asyncIterator]() {
            while (true) {
              const { done, value } = await reader.read();
              let jsonData = {};
              if (done) {
                jsonData = { data: "", event: "message_end" };
                yield jsonData;
                break;
              }
              const chunk = decoder.decode(value);
              const lines = chunk;
              for (const line of lines) {
                const jsonData = { answer: line, event: "message" };
                if (jsonData.conversation_id) {
                  conversation_id = jsonData.conversation_id;
                }

                yield jsonData;
              }
            }
          },
        },
      };
    }
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const resetConversationId = () => {
  conversation_id = "";
};

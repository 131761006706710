import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header/Header';
import CanvasList from '../components/CanvasList/CanvasList';

const HomePage = () => {
  
  const navigate = useNavigate();

  const handleCanvasItemClick = (canvas) => {
    navigate('/canvas', { state: { selectedCanvas: canvas } });
  };
  return (
    <div>
      <Header />  
      <CanvasList onCanvasItemClick={handleCanvasItemClick} />
    </div>
  );
};

export default HomePage;

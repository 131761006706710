import React from 'react';
import AgentTile from '../AgentTile/AgentTile';
import styles from './AgentList.module.css';


const AgentList = ({ agents, onAgentClick }) => {
  return (
    <div className={styles.agentList}>
      {agents.map((agent, index) => (
        <AgentTile
          key={index}
          name={agent.title}
          description={agent.description}
          onClick={agent.clickable ? () => onAgentClick(agent) : ()=>{}}
        />
      ))}
    </div>
  );
};

export default AgentList;

import React, { useState, useEffect, useRef } from "react";
import styles from "./AgentMainContent.module.css";
import { sendChatMessage } from "./chatAPI";
import { useAuthenticator } from "@aws-amplify/ui-react";
import Disclaimer from "./Disclaimer";
import SystemMessage from "./SystemMessage";

const AgentMainContent = ({
  myData,
  onNewMessage,
  onStreamingMessage,
  messages,
  streamingMessage,
  onUserQuery,
  convoId,
  token,
}) => {
  const [userQuery, setUserQuery] = useState("");
  const textareaRef = useRef(null);
  const chatContainerRef = useRef(null);

  const { user } = useAuthenticator((context) => [context.user]);

  const handleUserQueryChange = (e) => {
    setUserQuery(e.target.value);
  };

  const handleUserQuerySubmit = async (e) => {
    if (e.type === "click" || (e.type === "keydown" && e.key === "Enter")) {
      e.preventDefault();
      if (userQuery.trim()) {
        onUserQuery(userQuery);
        setUserQuery("");
      }

      let inputs = {};
      myData.inputs.forEach((input) => {
        inputs[input.dify_name] = input.type === "text" ? userQuery : "";
      });
      try {
        const result = await sendChatMessage(
          inputs,
          userQuery,
          myData.agent_api_key,
          myData.response_mode,
          user.username,
          convoId,
          token
        );

        if (result.type === "blocking") {
          if (result.data.answer) {
            onNewMessage(result.data.answer, "agent");
          }
        } else if (result.type === "streaming") {
          let fullMessage = "";
          for await (const chunk of result.stream) {
            if (chunk.event === "message") {
              fullMessage += chunk.answer;
              onStreamingMessage(chunk.answer, "agent", chunk.id, false, false);
            } else if (chunk.event === "message_end") {
              onStreamingMessage(null, "agent", chunk.id, true, false);
              onNewMessage(fullMessage, "agent");
            }
          }
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  // Adjust textarea height based on content
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height =
        textareaRef.current.scrollHeight + "px";
    }
  }, [userQuery]);

  // Scroll to the bottom when messages change
  // useEffect(() => {
  //   if (chatContainerRef.current) {
  //     chatContainerRef.current.scrollTop =
  //       chatContainerRef.current.scrollHeight;
  //   }
  // }, [messages, streamingMessage]);

  useEffect(() => {
    if (myData?.agent_api_key === "base") {
      handleUserQuerySubmit({ type: "submit" });
    }
  }, []);
  return (
    <div className={styles.mainContent}>
      <div
        id="chatConversation"
        className={styles.chatContainer}
        ref={chatContainerRef}
      >
        {messages.map((message, index) => (
          <SystemMessage message={message} id={message.id || index} />
        ))}
        {/* highlight-start */}
        {streamingMessage && (
          <SystemMessage
            message={streamingMessage}
            id={streamingMessage.id}
            loading={messages.loading}
          />
        )}
        {/* highlight-end */}
      </div>
      <div className={styles.stickyBottom}>
        <Disclaimer />
        {/* <div className={styles.bottomBar}> */}
        <div className={styles.chatInputContainer}>
          <textarea
            disabled
            ref={textareaRef}
            placeholder="Follow up on the response"
            className={styles.chatInput}
            id="userQuery"
            value={userQuery}
            onChange={handleUserQueryChange}
            onKeyDown={handleUserQuerySubmit}
            rows={1}
          />
          <button className={styles.sendButton}>
            <img
              id="send-button"
              src="/icons/icon-arrow-disabled.svg"
              alt="Send"
              onClick={handleUserQuerySubmit}
            />
          </button>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default AgentMainContent;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './ActionButtons.module.css';

function ActionButtons({notAgentPage}) {
  const navigate = useNavigate();

  const handleChatAgentClick = () => {
    navigate('/agent', { state: { configFile: 'blank_chat.json' } });
  };
  
  return (
    <div className={styles.buttons}>
      {notAgentPage && <button className={styles.chatAgent} onClick={handleChatAgentClick}>New Chat</button>}
    </div>
  );
}

export default ActionButtons;

import React from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import CanvasPage from "./pages/CanvasPage";
import AgentPage from "./pages/AgentPage";
import AgentPageWebsocket from "./pages/AgentPageWebsocket";
import "./App.css";

import { Amplify } from "aws-amplify";

import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import awsExports from "./aws-exports";
import Header from "./components/LogIn/Header";
import Footer from "./components/LogIn/Footer";

Amplify.configure(awsExports);

function App() {
  const components = { Header, Footer };
  return (
    <Authenticator hideSignUp={true} components={components}>
      <div className="App">
        <Routes>
          <Route path="/login" element={<HomePage />} />
          <Route path="/logout" element={<HomePage />} />
          <Route path="/" element={<HomePage />} />
          <Route path="/canvas" element={<CanvasPage />} />
          <Route path="/agent" element={<AgentPage />} />
          <Route path="/test" element={<AgentPageWebsocket />} />

          <Route path="*" element={<HomePage />} />
        </Routes>
      </div>
    </Authenticator>
  );
}

export default App;

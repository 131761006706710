import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom"; // Import useLocation
import Header from "../components/Header/Header";
import NavBar from "../components/NavBar/NavBar";
import CanvasDescription from "../components/CanvasDesciption/CanvasDescription";
import CanvasNavigation from "../components/CanvasNavigation/CanvasNavigation";
import AgentList from "../components/AgentList/AgentList";
import canvases from "../data/canvas.json";

const CanvasPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [selectedCanvas, setSelectedCanvas] = useState("Strategic");

  const handleProjectSelect = (agent) => {
    navigate("/agent", { state: { configFile: agent.config } });
  };

  useEffect(() => {
    if (location.state && location.state.selectedCanvas) {
      setSelectedCanvas(location.state.selectedCanvas.name); // Ensure the selected canvas is set correctly
    } else {
      setSelectedCanvas("Strategic"); // Default to 'Strategic' if no state is passed
    }
  }, [location.state]);

  const selectedCanvasData = canvases.find(
    (canvas) => canvas.name === selectedCanvas
  );

  return (
    <div>
      <Header />
      <NavBar />
      <CanvasDescription
        name={selectedCanvasData?.name || ""}
        description={selectedCanvasData?.description || ""}
      />
      <CanvasNavigation
        canvases={canvases}
        selectedCanvas={selectedCanvas}
        onCanvasSelect={setSelectedCanvas}
      />
      <AgentList
        agents={selectedCanvasData.agents}
        onAgentClick={handleProjectSelect}
      />
    </div>
  );
};

export default CanvasPage;

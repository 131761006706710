import React from 'react';
import CanvasItem from '../CanvasItem/CanvasItem';
import ActionButtons from '../ActionButtons/ActionButtons';
import styles from './CanvasList.module.css';
import canvases from '../../data/canvas.json';
const CanvasList = ({ onCanvasItemClick }) => {

  return (
    <div className={styles.canvasList}>
      <div className={styles.headerActions}>
        <h2>SELECT A CANVAS</h2>
        <ActionButtons notAgentPage={true}/>
      </div>
      <ul>
      {canvases.map((canvas, index) => (
          <CanvasItem
            key={index}
            name={canvas.name}
            description={canvas.description}
            onClick={() => onCanvasItemClick(canvas)} 
          />
        ))}
      </ul>
    </div>
  );
}

export default CanvasList;

import React, { useEffect, useState } from "react";
import styles from "./Header.module.css";
import { useNavigate, useMatch } from "react-router-dom";

import { useAuthenticator } from "@aws-amplify/ui-react";

function Header() {
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const navigate = useNavigate();
  const root = useMatch("/");
  const [userName, setUserName] = useState("User");

  useEffect(() => {
    const getUser = () => {
      let fullUser = "user";
      let name = ["Canvas User"];
      if (user?.username) {
        if (user.username.includes("_")) {
        fullUser = user.username.split("_");
        name = fullUser[1].split("@");
        } else {
          name = [user.username];
        }
      }

      setUserName(name[0]);
    };

    getUser();
  }, []);

  return (
    <>
      <header className={root ? styles.header : styles.agentHeader}>
        <div className={root ? styles.headerTop : styles.agentHeaderTop}>
          <div className={root ? styles.logo : null}>
            {root ? (
              <img src="/cm_logo.png" alt="Critical Mass Logo" />
            ) : (
              <img
                src="logo.png"
                className={styles.agentLogo}
                onClick={() => navigate("/")}
                alt="Canvas AI Logo"
              />
            )}
          </div>
          <div className={styles.accountDropdown}>
            <img src="/icons/icon-username.svg" alt="User Icon" />
            <span>
              Welcome, <b>{userName}</b>
            </span>
            <img src="/icons/icon-dropdown-filter.svg" alt="Arrow Down" />
            <ul className={styles.dropdownMenu}>
              <li>
                <a
                  href="https://app.smartsheet.com/b/form/01d734a68bf3447a9ca302566d42a59a"
                  target="_blank"
                  rel="noopener Noreferrer"
                >
                  Send Feedback
                </a>
              </li>
              <li onClick={signOut}>Log Out</li>
            </ul>
          </div>
        </div>

        {root ? (
          <div className={styles.headerContent}>
            <img src="/logo.png" alt="Canvas Logo" />
            <p>
              Welcome! Canvas.Ai is a springboard within CM's unique strategic
              process designed to gather, analyze, and apply data and insights
              to create effective, consumer-focused brand experiences.
            </p>
          </div>
        ) : null}
      </header>
      {/* <div className={styles.headerActions}>
        <h2>Select a Canvas</h2>  <button className={styles.viewAllProjects}>View All Projects</button> 
      </div>*/}
    </>
  );
}

export default Header;

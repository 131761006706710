// AgentContent.jsx

import React, { useState, useEffect } from "react";
import AgentInput from "./AgentInput";
import AgentMainContent from "./AgentMainContent";
import styles from "./AgentContent.module.css";
const AgentContent = ({ myData, token }) => {
  const [messages, setMessages] = useState([]);
  // highlight-next-line
  const [conversationId, setConversationId] = useState(null);
  const [streamingMessage, setStreamingMessage] = useState(null);

  useEffect(() => {
    // Reset conversation ID when the component mounts
    createConversationId();

    if (myData?.agent_api_key === "base") {
      console.log("Please provide an agent API key");
    }
  }, []);

  const createConversationId = () => {
    // This function is used to create a new conversation ID
    // You can use this function to create a new conversation ID
    let conversation_Id =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15) +
      Date.now().toString(36);
    setConversationId(conversation_Id);
  };

  const handleNewMessage = (message, sender) => {
    setMessages((prevMessages) => [...prevMessages, { text: message, sender }]);
  };

  // highlight-start
  const handleStreamingMessage = (
    chunk,
    sender,
    messageId,
    isComplete,
    loading
  ) => {
    if (isComplete) {
      setStreamingMessage(null);
    } else {
      setStreamingMessage((prevStreaming) => ({
        text: (prevStreaming?.text || "") + (chunk || ""),
        sender,
        id: messageId,
        loading: loading,
      }));
    }
  };

  const handleUserQuery = (query) => {
    handleNewMessage(query, "user");
    // Here you might want to send this query to your API as well,
    // similar to how you handle the initial user input in AgentInput
  };

  return (
    <div id={styles["agent-content"]}>
     
        <AgentInput
          myData={myData}
          onNewMessage={handleNewMessage}
          onStreamingMessage={handleStreamingMessage}
          convoId={conversationId}
          token={token}
        />
  
      <AgentMainContent
        myData={myData}
        onNewMessage={handleNewMessage}
        onStreamingMessage={handleStreamingMessage}
        messages={messages}
        streamingMessage={streamingMessage}
        onUserQuery={handleUserQuery}
        convoId={conversationId}
        token={token}
      />
    </div>
  );
};

export default AgentContent;

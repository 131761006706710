import React from 'react';
import styles from './Disclaimer.module.css';

const Disclaimer = () => {
  return (
    <div className={styles.disclaimer}>
      Canvas.Ai may not always provide precise results. Please verify critical information independently. 
      <a href="/learn-more" className={styles.learnMore}>Learn More</a>
    </div>
  );
};

export default Disclaimer;

import { fetchAuthSession } from "aws-amplify/auth";
import { useEffect, useState } from "react";

const useGetToken = () => {
  // state to keep track of loading
  const [loadingTokenData, setLoadingTokenData] = useState(false);

  // state for data itself
  const [token, setToken] = useState(null);

  // effect to fetch data
  useEffect(() => {
    const fetchData = async () => {
      try {
        // set data to loading
        setLoadingTokenData(true);

        const authSesh = await fetchAuthSession();

        // set data in state and loading to false
        setLoadingTokenData(false);
        setToken(authSesh.tokens?.idToken?.toString());
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchData();
  }, []);

  // return the data and loading state from this hook
  return [loadingTokenData, token];
};

export { useGetToken };

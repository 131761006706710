import React from 'react';
import styles from './ViewPromptModal.module.css';

const ViewPromptModal = ({ onClose, prompt }) => {
  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modal}>
        <button className={styles.closeButton} onClick={onClose}>&times;</button>
        <div className={styles.modalContent}>
          <h2>View Prompt</h2>
          <div className={styles.promptContainer}>
            <pre>{prompt}</pre>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewPromptModal;

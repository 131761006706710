import React, { useState, useRef } from "react";
import styles from "./AgentMainContent.module.css";
import { ThumbsDown, ThumbsUp, CopyIcon } from "../IconFiles";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const SystemMessage = ({ message }) => {
  const selectText = () => {
    handleCopyClick(message.text);
  };

  const handleCopyClick = (contentToCopy) => {
    navigator.clipboard.writeText(contentToCopy);
    // Reset the copy success state after a short delay
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 2000);
  };
  const [copySuccess, setCopySuccess] = useState(false);
  return (
    <div
      className={
        message.sender === "agent" ? styles.agentMessage : styles.userMessage
      }
    >
      {message.loading ? (
        <div className={styles.loading}>
          Loading <span>.</span>
          <span>.</span>
          <span>.</span>
        </div>
      ) : (
        <>
          <div className={styles.iconAndText}>
            {message.sender === "agent" && (
              <img
                src="/icons/icon-username.svg"
                alt="User Icon"
                className={styles.userIcon}
              />
            )}
            <div className={styles.messageBubble}>
              <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                components={{
                  table: ({ node, ...props }) => (
                    <table className={styles.table} {...props} />
                  ),
                  th: ({ node, ...props }) => (
                    <th className={styles.tableHeader} {...props} />
                  ),
                  td: ({ node, ...props }) => (
                    <td className={styles.tableCell} {...props} />
                  ),
                  pre: ({ node, ...props }) => (
                    <pre className={styles.codeBlock} {...props} />
                  ),
                  code: ({ node, ...props }) => (
                    <code className={styles.code} {...props} />
                  ),
                }}
              >
                {message.text}
              </ReactMarkdown>
            </div>
          </div>
          <br />

          {message.sender === "agent" && (
            <div className={styles.actionIcons} onClick={(e) => selectText()}>
              <CopyIcon />
              <div
                className={`${styles.copySuccess} ${
                  !copySuccess ? styles.change : ""
                }`}
              >
                &#10003; Text copied
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SystemMessage;

import { useState, useRef } from "react";
import {
  useFloating,
  autoUpdate,
  arrow,
  offset,
  flip,
  shift,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  FloatingPortal,
  FloatingArrow,
} from "@floating-ui/react";

import style from "./Tooltip.module.css";

function Tooltip({ text }) {
  const [isOpen, setIsOpen] = useState(false);

  const arrowRef = useRef(null);

  const ARROW_HEIGHT = 5;
  const GAP = 1;
  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: "top",
    // Make sure the tooltip stays on the screen
    whileElementsMounted: autoUpdate,
    middleware: [
      shift({ padding: 22 }),
      arrow({
        element: arrowRef,
      }),
      offset(ARROW_HEIGHT + GAP),

      shift(),
    ],
  });

  // Event listeners to change the open state
  const hover = useHover(context, { move: false });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  // Role props for screen readers
  const role = useRole(context, { role: "tooltip" });

  // Merge all the interactions into prop getters
  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    dismiss,
    role,
  ]);
  return (
    <>
      <div
        ref={refs.setReference}
        {...getReferenceProps()}
        className={style.icon}
      >
        <img
          src="/icons/icon-help.svg"
          alt="Tooltip"
          //   className={style.tooltipIcon}
        />
      </div>
      <FloatingPortal>
        {isOpen && (
          <div
            className={`Tooltip ${style["tooltip-text"]}`}
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            {text}
            <FloatingArrow
              ref={arrowRef}
              context={context}
              className={style.arrow}
              fill="#323232"
            />
          </div>
        )}
      </FloatingPortal>
    </>
  );
}

export default Tooltip;

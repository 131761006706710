import React, { useState, useEffect, useRef } from "react";
import ViewPromptModal from "../ViewPromptModal/ViewPromptModal";
import style from "./AgentInput.module.css";
import { sendChatMessage, getConversationId } from "./chatAPI";
import { useAuthenticator } from "@aws-amplify/ui-react";
import Tooltip from "../Tooltip";

const AgentInput = ({
  myData,
  onNewMessage,
  onStreamingMessage,
  convoId,
  token,
}) => {
  const { user } = useAuthenticator((context) => [context.user]);

  const formRef = useRef(null);

  const [isModalOpen, setModalOpen] = useState(false);
  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  // Track input state
  const [inputValues, setInputValues] = useState({});

  useEffect(() => {
    if (convoId && myData?.agent_api_key === "base") {
      console.log("Please provide an agent API key");
      formRef.current.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true })
      );
      document.getElementById("userQuery").disabled = false;
    }
  }, [convoId]);

  // Initialize inputValues state with keys for all inputs and empty values
  useEffect(() => {
    const initialValues = {};
    myData["inputs"].forEach((item) => {
      initialValues[item.dify_name] = "";
    });
    setInputValues(initialValues);
  }, [myData]);

  // Check required fields
  const allRequiredFieldsPopulated = () => {
    return myData["inputs"].every((item) => {
      return (
        !item.required || (item.required && inputValues[item.dify_name] !== "")
      );
    });
  };

  // Handle input change
  const handleInputChange = (e, difyName) => {
    setInputValues({
      ...inputValues,
      [difyName]: e.target.value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const result = await sendChatMessage(
        inputValues,
        myData.user_first_prompt,
        myData.agent_api_key,
        myData.response_mode,
        user.username,
        convoId,
        token
      );

      if (result.type === "blocking") {
        if (result.data.answer) {
          onNewMessage(result.data.answer, "agent");
        }
      } else if (result.type === "streaming") {
        let fullMessage = "";
        for await (const chunk of result.stream) {
          if (chunk.event === "message") {
            fullMessage += chunk.answer;
            onStreamingMessage(chunk.answer, "agent", "original", false, false);
          } else if (chunk.event === "message_end") {
            onStreamingMessage(null, "agent", "original", true, false);
            onNewMessage(fullMessage, "agent");
          }
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSubmitButtonClick = () => {
    formRef.current.dispatchEvent(
      new Event("submit", { cancelable: true, bubbles: true })
    );
    document.getElementById("GenerateButton").disabled = true;
    document.getElementById("GenerateButton").style.display = "none";
    const inputs = document.querySelectorAll("input");
    inputs.forEach((input) => {
      input.disabled = true;
    });
    document.getElementById("userQuery").disabled = false;

    document.getElementById("send-button").src = "/icons/icon-arrow-active.svg";
  };

  return (
    <>
      {myData.agent_api_key === "base" ? (
        <form className="form" ref={formRef} onSubmit={handleSubmit} hidden>
          <input
            type="hidden"
            value={myData.user_first_prompt}
            name="user_first_prompt"
          />
          <input
            type="hidden"
            value={myData.agent_api_key}
            name="agent_api_key"
          />
          <div className={style["form-group"]}>
            <textarea
              rows={1}
              type="text"
              title={"system_prompt"}
              id={"system_prompt"}
              name={"system_prompt"}
              value={"Hi"}
            />
          </div>
        </form>
      ) : (
        <div className={style.container}>
          <div className={style["scrollable-content"]}>
            <form className="form" ref={formRef} onSubmit={handleSubmit}>
              <input
                type="hidden"
                value={myData.user_first_prompt}
                name="user_first_prompt"
              />
              <input
                type="hidden"
                value={myData.agent_api_key}
                name="agent_api_key"
              />

              {myData["inputs"].map((item, index) => (
                <div className={style["form-group"]} key={index}>
                  <div className={style["tooltip-container"]}>
                    <label htmlFor={item.dify_name}>
                      {item.display_name} {item.required ? "*" : ""}
                    </label>
                    {item.tooltip && <Tooltip text={item.tooltip} />}
                  </div>
                  <textarea
                    rows={1}
                    type="text"
                    key={index}
                    placeholder={item.placeholder_text}
                    required={item.required}
                    title={item.tooltip}
                    id={item.dify_name}
                    name={item.dify_name}
                    value={inputValues[item.dify_name] || ""}
                    onChange={(e) => handleInputChange(e, item.dify_name)}
                  />
                  {/*<small>{item.tooltip}</small>*/}
                </div>
              ))}
              <div className={style["form-group"]}>
                <div className={style["view-prompt"]} onClick={handleOpenModal}>
                  <span>View Prompt </span>
                </div>
              </div>
            </form>
          </div>
          <div id="fixedContainer" className={style["fixed-bottom-content"]}>
            <button
              id="GenerateButton"
              type="submit"
              className={style["generate-button"]}
              onClick={handleSubmitButtonClick}
              disabled={!allRequiredFieldsPopulated()}
            >
              <img
                src="/icons/icon-generate-selected.svg"
                alt="Generate Sparkle Icon"
              />
              &nbsp;Generate
            </button>
          </div>
        </div>
      )}
      {isModalOpen && (
        <ViewPromptModal
          onClose={handleCloseModal}
          prompt={myData.agent_prompt}
        />
      )}
    </>
  );
};

export default AgentInput;
